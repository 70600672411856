<template>
  <v-dialog
    v-model="dialog"
    persistent
    tile
    max-width="500px"
  >
    <v-card
      tile
      class="report-card elevation-0"
    >
      <v-card-title primary-title>
        <div class="card-title">
          {{ $t('components.report_dialog.report_post') }}
        </div>
        <div class="card-subtitle">
          {{ $t('components.report_dialog.card_subtitle') }}
        </div>
      </v-card-title>
      <v-card-text class="card-text">
        <ValidationProvider
          v-slot="{errors}"
          :name="$t('components.report_dialog.inform_problem_label')"
          rules="required"
        >
          <v-text-field
            v-model="comment"
            :label="$t('components.report_dialog.inform_problem')"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-card-text>
      <v-card-actions class="actions">
        <v-spacer />
        <v-btn
          text
          small
          depressed
          class="cancel-report"
          @click="closeReport"
        >
          {{ $t('components.report_dialog.cancel') }}
        </v-btn>
        <v-btn
          text
          small
          depressed
          type="submit"
          class="send-report"
          @click="sendReport"
        >
          {{ $t('components.report_dialog.send_report') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ReportDialog',
  props: {
    postId: {
      type: Number,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comment: '',
    };
  },
  computed: {
    ...mapGetters([
      'getReport',
    ]),
  },
  watch: {
    getReport(value) {
      if (value) {
        this.closeReport();
        this.addSuccessMessage(this.$t('components.report_dialog.success_message'));
      }
    },
  },
  methods: {
    ...mapActions([
      'createReport',
      'addSuccessMessage',
      'closeReport',
    ]),
    sendReport() {
      this.createReport({
        comment: this.comment,
        post_id: this.postId,
      });
    },
    closeReport() {
      this.$emit('onCloseDialog');
    },
  },
};
</script>

<style lang="scss">
.v-dialog {
  border-radius: 4px;

  .report-card {
    .card-title {
      @include font-open-sans-bold;
      color: $text-color-primary;
      font-size: $font-size-base;
      margin-bottom: 16px;
    }

    .v-card__title--primary {
      padding: 24px 24px 0px;
    }

    .v-card__title {
      padding: 24px 24px 0px;
    }

    .card-subtitle {
      @include font-monteserrat-regular;
      color: $text-color-secondary;
      font-size: $font-size-extra-small;
      margin-bottom: 16px;
      line-height: 16px;
    }

    .card-text {
      @include font-monteserrat-semi-bold;
      font-size: $font-size-extra-small;
      color: $text-color-secondary;
      padding: 0px 24px;

      input {
        font-size: $font-size-extra-small;
      }

      .v-input .v-label {
        font-size: $font-size-extra-small;
      }

      .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
      }
    }

    .actions {
      text-align: right;
      font-weight: bold;
      font-size: $font-size-extra-small;
      padding: 0 18px 24px 0;
      text-transform: capitalize;

      .send-report {
        @include font-open-sans-bold;
        padding: 0px;
        color: $secondary-button-color;
        text-transform: capitalize;
        letter-spacing: normal;
      }

      .cancel-report {
        @include font-open-sans-bold;
        color: $text-color-secondary;
        padding: 0px;
        text-transform: capitalize;
        margin-right: 24px;
        letter-spacing: normal;
      }
    }
  }
}
</style>
