<template>
  <v-card
    class="post-card elevation-0"
  >
    <v-row
      no-gutters
      class="post-header"
    >
      <v-col
        cols="11"
        class="postable pa-0"
      >
        <v-icon
          class="icon"
          size="12"
        >
          home
        </v-icon>
        {{ postCreatorName }}
      </v-col>
      <v-col
        v-if="!details"
        cols="1"
        class="more-options pa-0"
        @click="moreOptions(post.id)"
      >
        <v-icon size="12">
          more_vert
        </v-icon>
      </v-col>
      <v-col
        cols="12"
        class="post-infos pa-0"
      >
        <v-icon size="12">
          access_time
        </v-icon>
        {{ formatDate(post.created_at) }}
      </v-col>
    </v-row>
    <div
      class="post-image"
      @click="postDetails"
    >
      <v-img
        v-if="photoUrl"
        :height="imageHeight"
        :src="photoUrl"
      />
    </div>
    <div
      v-if="!details"
      :ref="`postBody${post.id}`"
      class="post-body"
      @click="postDetails"
    >
      <div class="post-title">
        {{ post.title }}
      </div>

      <!-- eslint-disable-next-line -->
      <div v-html="post.body" />

      <div
        v-if="hasSeeMore"
        class="see-more"
      >
        ...<span>{{ $t('views.feed_list.see_more') }}</span>
      </div>
    </div>
    <!-- eslint-disable-next-line -->
    <div v-else v-html="post.body" class="post-body-details" />

  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PostCard',
  props: {
    post: {
      type: Object,
      default: () => {},
    },
    details: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasSeeMore: false,
    };
  },
  computed: {
    photoUrl() {
      return this.post.photo;
    },
    imageHeight() {
      return this.details ? '100%' : '160px';
    },
    postCreatorName() {
      return this.post.postable.name;
    },
  },
  mounted() {
    this.addSeeMore();
  },
  methods: {
    ...mapActions([
      'setPaginationState',
      'clearPosts',
    ]),
    addSeeMore() {
      const postBodyReference = this.$refs[`postBody${this.post.id}`];
      if (postBodyReference && postBodyReference.offsetHeight > 40) {
        if (this.post.body.split(' ').length > 20) {
          this.hasSeeMore = true;
        }
      }
    },
    moreOptions() {
      this.$emit('moreOptions', this.post.id);
    },
    postDetails() {
      this.$emit('postDetails', this.post);
    },
  },
};
</script>

<style lang="scss">
  .post-card {
    margin-bottom: 24px;

    .post-header {
      @include font-open-sans-bold;
      .postable {
        color: $titles-color-primary;
        font-size: $font-size-extra-small;
        line-height: 16px;

        .icon {
          color: $titles-color-primary;
        }
      }

      .post-infos {
        @include font-open-sans-semi-bold;
        color: $text-color-secondary;
        font-size: $font-size-extra-super-small;
        margin-bottom: 8px;
      }

      .more-options {
        text-align: right;
        display: block;
      }
    }

    .post-body {
      @include font-monteserrat-regular;
      font-size: $font-size-extra-small;
      background: $border-color;
      line-height: 16px;
      padding: 8px 8px 0;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      position: relative;
      width: 100%;
      height: auto;
      overflow: hidden;
      hyphens: auto;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      .post-title {
        margin-bottom: 16px;
      }

      .see-more {
        @include font-monteserrat-semi-bold;
        background: $border-color;
        position: absolute;
        right: 8px;
        bottom: 0;
        span {
          color: $titles-color-primary;
        }
      }
    }

    .post-body-details {
      @include font-monteserrat-regular;
      font-size: $font-size-extra-small;
      line-height: 16px;
      margin-top: 16px;
    }
  }
</style>
