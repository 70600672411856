<template>
  <div class="post-details">
    <post
      :post="postDetails"
      :details="true"
    />
    <report-dialog
      :post-id="postDetails.id"
      :dialog="dialogChange"
      @onCloseDialog="closeReportDialog"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import Post from '@/components/cards/Post.vue';
import ReportDialog from '@/components/dialogs/ReportDialog.vue';

export default {
  name: 'Details',
  components: {
    Post,
    ReportDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'getPost',
      'getToolbarNavOptions',
    ]),
    postDetails() {
      return this.getPost || { postable: {}, authorable: {} };
    },
    dialogChange() {
      return this.dialog;
    },
  },
  mounted() {
    this.findPost(this.$route.params.postId);
  },
  watch: {
    getPost(value) {
      this.changeToolbarText(value.title);
      const navOptions = {
        options: {
          spacer: {
            has: true,
          },
          moreOptions: {
            has: true,
            actions: [
              {
                title: this.$t('components.labels.report_post'),
                method: this.moreOptionsDialog,
              },
            ],
          },
        },
      };
      this.changeToolbarNav(navOptions);
    },
  },
  methods: {
    ...mapActions([
      'findPost',
      'changeToolbarNav',
      'changeToolbarText',
    ]),
    moreOptionsDialog() {
      this.dialog = true;
    },
    closeReportDialog() {
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss">
.post-details {
  padding: 16px;
}
</style>
